import { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Box,
  Drawer,
  Stack,
  Divider,
  Typography,
  IconButton,
  TextField,
  Alert,
  Button,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  MenuItem,
  CircularProgress,
  LinearProgress,
  Card,
  CardHeader,
  CardContent,
  CardActions,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

import { fetchData } from "../../../../fetchData";
import { fetchDominioData } from "../../../../fetchDominioData";

import useHrmVacationStore from "../../../../stores/hrVacation";
import useAuthStore from "../../../../stores/auth";
import DatePickerField from "../../../../components/DatePickerField";
import { formatDate } from "../../../../utils";

interface FormValues {
  period_init_date: string;
  vacation_init_date: string;
  days: string;
  advance_13o: string;
  allowance_days: string;
  observations: string;
}

const HrmEmployeeVacationDrawer = () => {
  const company = useAuthStore((state) => state.company);
  const state = useHrmVacationStore((state) => state.drawer);
  const employee: any = useHrmVacationStore((state) => state.drawerData);
  const toggleDrawer = useHrmVacationStore((state) => state.toggleDrawer);
  const [activeStep, setActiveStep] = useState(0);
  const [err, setErr] = useState(false);
  const [success, setSuccess] = useState(false);
  const [progress, setProgress] = useState(false);
  const [periods, setPeriods] = useState([]);
  const [periodsProgress, setPeriodsProgress] = useState(false);
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    watch,
    trigger,
    setError,
    reset,
    setFocus,
    control,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      period_init_date: "",
      vacation_init_date: "",
      days: "",
      advance_13o: "",
      allowance_days: "",
      observations: "",
    },
  });

  const getPeriodData = async () => {
    setPeriodsProgress(true);
    try {
      const response = await fetchDominioData(
        `/empresa/${company.reference}/funcionario&${employee.i_empregados}&ferias`,
        {
          method: "GET",
        },
      );

      if (response) {
        console.log(response.rows);
        setPeriods(response.rows);
      }
    } catch (error) {
      console.log(error);
    }
    setPeriodsProgress(false);
  };

  const handleNext = async () => {
    if (
      activeStep === 1 &&
      !(await trigger(
        [
          "period_init_date",
          "vacation_init_date",
          "days",
          "advance_13o",
          "allowance_days",
        ],
        {
          shouldFocus: true,
        },
      ))
    )
      return;

    if (activeStep + 1 < steps.length)
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSetPeriod = (value: string) => {
    setValue("period_init_date", value);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const ErrorAlert = () => {
    return (
      <Alert
        severity="error"
        sx={{
          flexDirection: "column",
          alignItems: "center",
          textAlign: "cenrer",
        }}
      >
        <Stack alignItems="center" direction="column">
          <Typography textAlign="center" variant="h5">
            Ops!
          </Typography>
          <Typography textAlign="center">
            Não foi possível enviar a solicitação no momento. Tente novamente
            mais tarde.
          </Typography>
          <Button
            variant="contained"
            onClick={() => toggleDrawer()}
            sx={{ mt: 2 }}
          >
            Fechar
          </Button>
        </Stack>
      </Alert>
    );
  };

  const SuccessAlert = () => {
    return (
      <Alert
        severity="success"
        sx={{
          height: "100%",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "cenrer",
        }}
      >
        <Stack alignItems="center" direction="column">
          <Typography textAlign="center" variant="h5">
            Muito bem!
          </Typography>
          <Typography textAlign="center">
            Solicitação enviada com sucesso.
          </Typography>
          <Button
            variant="contained"
            onClick={() => toggleDrawer()}
            sx={{ mt: 2 }}
          >
            Fechar
          </Button>
        </Stack>
      </Alert>
    );
  };

  const Progress = () => {
    return (
      <Alert
        severity="info"
        sx={{
          flexDirection: "column",
          alignItems: "center",
          textAlign: "cenrer",
        }}
        icon={false}
      >
        <Stack spacing={1} alignItems="center" direction="column">
          <CircularProgress />
          <Typography textAlign="center" variant="h6">
            Enviando solicitação
          </Typography>
          <Typography textAlign="center">Aguarde...</Typography>
        </Stack>
      </Alert>
    );
  };

  const steps = [
    {
      label: "Selecione o Período Aquisitivo",
      description: "",
      outlet: (
        <Stack spacing={1}>
          {periodsProgress && <LinearProgress />}

          {periods.length > 1 && (
            <Alert
              severity="warning"
              sx={{
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              ATENÇÃO PARA
              <br />
              RISCO DE FÉRIAS EM DOBRO
            </Alert>
          )}

          {periods.map((row: any, index: number) => (
            <Card
              key={`period_${index}`}
              variant="outlined"
              sx={{ border: "1px solid #ECECEC" }}
            >
              <CardContent sx={{ p: "0 !important" }}>
                <Stack
                  spacing={1}
                  direction="row"
                  justifyContent="space-between"
                >
                  <Box>
                    <Typography variant="caption" component="div">
                      Data Inicial:
                    </Typography>
                    <Typography
                      variant="body2"
                      fontWeight="bold"
                      component="div"
                    >
                      {formatDate(row.DATA_INICIO, "D/M/Y")}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="caption" component="div">
                      Data Final:
                    </Typography>
                    <Typography
                      variant="body2"
                      fontWeight="bold"
                      component="div"
                    >
                      {formatDate(row.DATA_FIM, "D/M/Y")}
                    </Typography>
                  </Box>
                </Stack>
              </CardContent>
              {index === periods.length - 1 && (
                <CardActions sx={{ p: 0 }}>
                  <Button
                    size="small"
                    variant="contained"
                    sx={{ mt: 1 }}
                    type="button"
                    fullWidth
                    onClick={() =>
                      handleSetPeriod(formatDate(row.DATA_INICIO, "D/M/Y"))
                    }
                  >
                    Selecionar
                  </Button>
                </CardActions>
              )}
            </Card>
          ))}
        </Stack>
      ),
    },
    {
      label: "Solicitação",
      description: ``,
      outlet: (
        <Stack spacing={1}>
          <TextField
            required
            {...register("period_init_date")}
            disabled
            label="Data Inicial Período Aquisitivo"
            variant="filled"
          />

          <DatePickerField
            name="vacation_init_date"
            register={register}
            get={getValues}
            set={setValue}
            label="Data Inicial das Férias"
            variant="filled"
            required
            requiredtxt="Informe a data inicial das férias."
          />
          {errors?.vacation_init_date && (
            <Alert severity="error">
              {errors.vacation_init_date.message?.toString()}
            </Alert>
          )}

          <TextField
            required
            {...register("days", {
              required: "Informe os dias de férias",
            })}
            type="number"
            label="Dias de Férias"
            variant="filled"
          />
          {errors?.days && (
            <Alert severity="error">{errors.days.message?.toString()}</Alert>
          )}
          <TextField
            select
            required
            {...register("advance_13o", {
              required: "Selecione se terá adiantamento de 13º",
            })}
            label="Adiantamento 13º"
            variant="filled"
          >
            <MenuItem value="SIM">SIM</MenuItem>
            <MenuItem value="NÃO">NÃO</MenuItem>
          </TextField>
          {errors?.advance_13o && (
            <Alert severity="error">
              {errors.advance_13o.message?.toString()}
            </Alert>
          )}

          <TextField
            select
            required
            {...register("allowance_days", {
              required: "Selecione se terá dias de abono",
            })}
            label="Dias de Abono"
            variant="filled"
          >
            <MenuItem value="SIM">SIM</MenuItem>
            <MenuItem value="NÃO">NÃO</MenuItem>
          </TextField>
          {errors?.allowance_days && (
            <Alert severity="error">
              {errors.allowance_days.message?.toString()}
            </Alert>
          )}
          <TextField
            {...register("observations")}
            variant="filled"
            multiline
            rows={2}
            fullWidth
            label="Informação adicional"
            placeholder="Escreva aqui quaisquer informações complementares."
          ></TextField>
        </Stack>
      ),
    },
    {
      label: "Envio",
      description: "",
      outlet: <></>,
    },
  ];

  async function onSubmit(data: any) {
    setProgress(true);
    data.name = employee.nome;
    data.reference = company.reference;

    try {
      await fetchData(`/hrm/fopag/employee/${employee.i_empregados}/vacation`, {
        method: "POST",
        body: JSON.stringify(data),
      });
      setSuccess(true);
    } catch (error) {
      setErr(true);
      console.log(error);
    }

    setProgress(false);
  }

  useEffect(() => {
    if (!state) {
      reset();
      setActiveStep(0);
      setSuccess(false);
      setPeriods([]);
      setErr(false);
    } else {
      getPeriodData();
    }
  }, [state]);

  return (
    <Drawer
      anchor="left"
      open={state}
      onClose={() => {
        toggleDrawer();
      }}
    >
      <Box sx={{ p: 3, pt: 2, width: 400 }} width={300} height="100%">
        <Stack height="100%" spacing={2}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Stack
              direction="row"
              spacing={1}
              sx={{ flex: 1 }}
              alignItems="center"
            >
              <Typography
                variant="body1"
                sx={{ width: 170, wordWrap: "break-word" }}
              >
                Solicitar Férias
              </Typography>
            </Stack>
            <IconButton
              onClick={() => {
                toggleDrawer();
              }}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
          <Divider sx={{ my: 2 }} />
          <Typography variant="body1" fontWeight={600}>
            {employee.nome}
          </Typography>
          <Divider sx={{ my: 1 }} />
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stepper activeStep={activeStep} orientation="vertical">
              {steps.map((step, index) => (
                <Step key={step.label}>
                  <StepLabel>{step.label}</StepLabel>
                  <StepContent>
                    {step.description && (
                      <Typography variant="body2" sx={{ mb: 2 }}>
                        {step.description}
                      </Typography>
                    )}
                    {step.outlet}
                    {index === 1 && (
                      <Box sx={{ mb: 2 }}>
                        <div>
                          <Button
                            size="small"
                            variant="contained"
                            onClick={handleNext}
                            sx={{ mt: 1, mr: 1 }}
                            type={index === 1 ? "submit" : "button"}
                          >
                            {index < 1 && "Próximo"}
                            {index === 1 && "Enviar"}
                          </Button>
                          <Button
                            size="small"
                            onClick={handleBack}
                            sx={{ mt: 1, mr: 1 }}
                          >
                            Voltar
                          </Button>
                        </div>
                      </Box>
                    )}
                    {success && <SuccessAlert />}
                    {err && <ErrorAlert />}
                    {progress && <Progress />}
                  </StepContent>
                </Step>
              ))}
            </Stepper>
          </form>
        </Stack>
      </Box>
    </Drawer>
  );
};

export default HrmEmployeeVacationDrawer;
